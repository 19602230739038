* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.header{
  padding: 50px 50px 0;
}

.code-container {
  padding: 10px;
  margin: 0 50px 0;
  border: 1px solid black;
}

.actions{
  padding: 0 50px;
}

.login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 500px;
  height: 500px;
  margin-left: -250px;
  margin-top: -250px;
}